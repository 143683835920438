import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import Layout from "components/layout";
import SEO from "SEO";

import { setPhoneNumber } from 'state/actions/phoneNumber';

// 96824 - this file incl preview changes

const RenderProductPageWrapper = props => {
  useEffect(() => {
    props.setPhoneNumber(props.phoneNumber)
  }, [])

  return (
    <Layout
      location={props.location}
      isHomePage={true}
      language={props.language}
      alternateLanguages={props.alternateLanguages}
      localePhoneNumber={props.localePhoneNumber}
      universalProps={props.universalProps}
      errorMessages={props.errorMessages}
      icons={props.icons}
      footerProps={props.footerProps}
      headerData={props.headerData}
      preview={props.preview}
      previewHeaderData={props.headerData}
      previewFooterData={props.footerProps.node}
    >
      <SEO 
        meta={props.meta}
        location={props.location}
        isHomePage={true}
        language={props.language}
        alternateLanguages={props.preview ? [] : props.alternateLanguages}
        languageCodes={props.languageCodes}
        preview={props.preview}
        phoneNumber={props.phoneNumber}
      />
      {props.component}
    </Layout>
  )
 
};

const mapDispatchToProps = dispatch => ({
  setPhoneNumber: phoneNumber => dispatch(setPhoneNumber(phoneNumber))
})

export default connect(null, mapDispatchToProps)(RenderProductPageWrapper);

RenderProductPageWrapper.propTypes = {
  alternateLanguages: PropTypes.array,
  component: PropTypes.object,
  // // defaultBannerImage: PropTypes.object,
  errorMessages: PropTypes.array,
  footerProps: PropTypes.object,
  headerData: PropTypes.object,
  // // hero: PropTypes.object,
  // // heroImage: PropTypes.object,
  icons: PropTypes.array,
  language: PropTypes.string,
  languageCodes: PropTypes.array,
  localePhoneNumber: PropTypes.array,
  location: PropTypes.object,
  // // mapIcons: PropTypes.object,
  meta: PropTypes.object,
  phoneNumber: PropTypes.string,
  // // pilot: PropTypes.bool,
  preview: PropTypes.bool,
  setPhoneNumber: PropTypes.func,
  // // sideBarProps: PropTypes.object,
  // uid: PropTypes.string,
  universalProps: PropTypes.array,
};
